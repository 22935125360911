import { Avatar, Col, Layout, Row } from "antd";
import { useEffect, useMemo, useState } from "react";

import styled from "styled-components";
import { Canlendar } from "./Footer/calendar";
import dayjs from "dayjs";
import { getLocalStorage } from "utils/CommomFunction";

const Footer = ({ isShowCalendar = true, onChangeDate }) => {
  const timeZone = localStorage.getItem("time_zone") || "America/New_York";
  const bussinessTime = localStorage.getItem("bussinessTime");

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  const ColumnRight = () => {
    return (
      <Col>
        <Text
          style={{
            color: isOnline ? "green" : "red",
            fontWeight: "600",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <circle cx="6" cy="6" r="6" fill={isOnline ? "green" : "red"} />
          </svg>{" "}
          {isOnline ? "Connect" : "Disconnect"}
        </Text>
        <Text
          style={{
            fontWeight: "400",
            color: "black",
            marginTop: 5,
          }}
        >
          {" "}
          Version 0.1.1.1
        </Text>
      </Col>
    );
  };
  const ColumnCustomInfo = () => {
    const merchantInfo = JSON.parse(localStorage.getItem("MerchantInfo"));
    return (
      <Col style={{ textAlign: "left", width: 250 }}>
        <Row style={{ gap: 6 }}>
          <Col>
            <Avatar style={{ height: 54, width: 54 }} />
          </Col>
          <Col style={{ width: 190 }}>
            <Text style={{ fontWeight: "600" }}>
              {merchantInfo?.firstname} {merchantInfo?.lastname}
            </Text>
            <Text
              style={{
                fontSize: 14,
                marginTop: 6,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {merchantInfo?.restaurant_name} {"-"}{" "}
              {merchantInfo?.restaurant_address}
            </Text>
          </Col>
        </Row>
      </Col>
    );
  };
  function convertTime() {
    try {
      const now = new Date(); // Lấy giờ hiện tại
      return new Intl.DateTimeFormat("en-US", {
        timeZone: timeZone || "America/New_York",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(now);
    } catch (error) {
      return new Date();
    }
  }

  const currentDate = useMemo(() => {
    const date = dayjs(convertTime()).format("YYYY-MM-DD");
    const formattedTimeD = dayjs(convertTime()).format("HH:mm");
    const bussinessTimeD = bussinessTime?.slice(0, 5);
    if (formattedTimeD >= bussinessTimeD) {
      return date;
    }
    return dayjs(date).subtract(1, "day").format("YYYY-MM-DD");
  }, [convertTime, bussinessTime]);

  const [dateSelected, setDateSelected] = useState(
    dayjs(convertTime()).format("YYYY-MM-DD")
  );
  const onSelectDate = (date) => {
    setDateSelected(date);
    localStorage.setItem("dateFrom", date);
    onChangeDate(date);
  };
  const isLogin = () => {
    return getLocalStorage("access_token");
  };
  useEffect(() => {
    if (currentDate) {
      setDateSelected(currentDate);
      localStorage.setItem("dateFrom", currentDate);
      onChangeDate(currentDate);
    }
  }, [currentDate]);
  return (
    isLogin() && (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 1000,
        }}
      >
        <Layout.Footer
          style={{
            textAlign: "center",
            paddingInline: 16,
            paddingBlock: 16,
            background: "white",
          }}
        >
          <Row justify={"space-between"}>
            <ColumnCustomInfo />
            {isShowCalendar && (
              <DateSection>
                <Canlendar date={dateSelected} setDate={onSelectDate} />
                <TodayButton
                  onClick={() => onSelectDate(currentDate)}
                  isToday={dateSelected === currentDate}
                >
                  Today
                </TodayButton>
              </DateSection>
            )}
            <ColumnRight />
          </Row>
        </Layout.Footer>
      </div>
    )
  );
};

export default Footer;

const Text = styled.p`
  font-family: "Montserrat";
  color: black;
  font-size: 16px;
  font-weight: 400;
`;
const DateSection = styled.div`
  display: flex;
  margin-top: 6px;
  gap: 20px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  flex-wrap: wrap;
`;

const TodayButton = styled.button`
  border-radius: 6px;
  background: ${({ isToday }) =>
    isToday ? "var(--Active-Green, #0aa699)" : "var(--Green-Prima, white)"};
  color: ${({ isToday }) => (isToday ? "rgba(255, 255, 255, 1)" : "#0aa699")};
  white-space: nowrap;
  height: 63px;
  width: 120px;
  border: ${({ isToday }) => (isToday ? "none" : "1px solid #0aa699")};
  cursor: pointer;
  font-size: 20px;
  transition: background 0.3s ease, color 0.3s ease;

  &:hover {
    background: ${({ isToday }) =>
      isToday
        ? "var(--Hover-Green, #08c6a0)"
        : "var(--Hover-Light-Green, #08c6a0)"};
    color: white;
  }

  @media (max-width: 991px) {
    white-space: initial;
    padding: 10px 20px;
  }
`;
