import { Modal, Spin } from "antd";
import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "components/Button/CustomButton";
import moment from "moment";
import "./index.scss";
import { ThemeContext } from "handlers/ThemeContext";
import { getTypeTextOrder, TYPE_QUICK_ORDER } from "components/Oders-V2";
import PrinterIcon from "assets/icons/printerIcon";
import { rePrintOrder } from "apis/Print";
import { getLastFourChars } from "utils/Validation";

const ORDER_STATUS = {
  READY: "ready",
  COOKING: "cooking",
  SENT: "sent",
};

const ORDER_TYPE = {
  QUOTE: "QUOTE",
  ORDER: "ORDER",
};

const { info } = Modal;

export default function OrderDetail({
  isShowOrderDetail = false,
  onClose = () => {},
  data = null,
  handleSetCooking,
  handleSubmitCookingOrder,
  handleSubmitCompleteOrder,
  handleCancelItem,
  handleSetListItemAction,
  loading = false,
}) {
  const { t } = useTranslation();
  const [loadingCancel, setLoadingCancel] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const order = data;
  let listItems = [];
  let cartId = data?.items[0]?.cart_id;
  let type = data?.items[0]?.type;
  data?.items?.map((item) => {
    listItems.push(item?.id);
  });
  const onCloseModal = () => {
    onClose();
  };
  const reportIssue = () => {};
  const cooking = () => {
    let itemsVerified = [];
    data?.items?.forEach((item) => {
      if (item?.status !== "cancel") {
        itemsVerified.push(item?.id);
      }
    });

    console.log(itemsVerified);

    handleSetListItemAction(cartId, itemsVerified, type, "cooking");
  };

  const readyToShip = () => {
    console.log(data);
    handleSetListItemAction(cartId, listItems, type, "ready");
  };

  const completeOrder = () => {
    data?.items?.map((item) => {
      handleSubmitCompleteOrder(item?.id, data?.type);
    });
  };

  const getTextNameTable = () => {
    if (order?.table_name && order?.table_name === TYPE_QUICK_ORDER)
      return `QO - ${order?.cart_name}`;
    if (order?.table_name && order?.table_name !== TYPE_QUICK_ORDER)
      return `${order?.table_name} - ${order?.cart_name}`;
    if (!order?.table_name && order?.order_number)
      return `${getLastFourChars(order?.order_number)}`;
    return "Unknow";
  };

  const cancelItem = async () => {
    if (data?.type == ORDER_TYPE.QUOTE) {
      setLoadingCancel(true);
      // data?.items?.map((item) => {
      //   handleCancelItem(`${data?.type}-${item?.id}`);
      // });
      handleSetListItemAction(cartId, listItems, type, "cancel");
      setTimeout(() => {
        setLoadingCancel(false);
        onCloseModal();
      }, 1500);
    }
  };
  const { isLightMode } = useContext(ThemeContext);
  const isCancelAll = useMemo(
    () =>
      order?.items?.filter((item) => item?.status !== "cancel")?.length === 0,
    [order]
  );
  React.useEffect(() => {
    if (!loading) {
      onCloseModal();
    }
  }, [loading]);

  const renderBottom = () => {
    if (data?.status == ORDER_STATUS.READY) {
      return (
        // <CustomButton
        //   title={t("completeOrder")}
        //   onClick={completeOrder}
        //   width={"100%"}
        //   height={56}
        //   style={{
        //     color: "#fff",
        //     background: "#146C43",
        //     fontWeight: 600,
        //     fontSize: 18,
        //     marginTop: 16,
        //   }}
        //   isHover={false}
        //   renderIcon={() => (
        //     <img style={{ marginRight: 5 }} src={icon_delivery} />
        //   )}
        //   isLoading={loading}
        // />
        <></>
      );
    } else if (data?.status == ORDER_STATUS.COOKING) {
      return (
        <CustomButton
          title={t("Ready")}
          onClick={readyToShip}
          width={"100%"}
          height={56}
          style={{
            color: "#fff",
            background: "#112A5A",
            fontWeight: 600,
            fontSize: 18,
            marginTop: 16,
          }}
          isHover={false}
          isLoading={loading}
        />
      );
    }
    return (
      <div>
        <div className="flex-center" style={{ marginTop: 16 }}>
          <CustomButton
            title={t("Cook All")}
            onClick={cooking}
            width={"100%"}
            height={56}
            style={{
              color: "#fff",
              background: "#FFA600",
              fontWeight: 600,
              fontSize: 20,
            }}
            isHover={false}
            isLoading={loading}
            disabled={loadingCancel}
          />
        </div>
        {data?.type == ORDER_TYPE.QUOTE && !isCancelAll && (
          <div className="flex-center" style={{ marginTop: 16 }}>
            <CustomButton
              title={t("cancel")}
              onClick={cancelItem}
              width={"100%"}
              height={56}
              style={{
                background: "transparent",
                fontWeight: 600,
                fontSize: 18,
                color: "#E31837",
              }}
              isHover={false}
              isLoading={loadingCancel}
              disabled={loading}
            />
          </div>
        )}
      </div>
    );
  };

  const onRePrint = async () => {
    try {
      setIsLoading(true);
      const item_ids = order?.items
        .filter((item) => item.status !== "cancel")
        .map((item) => item.id);
      const response = await rePrintOrder({
        cart_id: cartId,
        bill_type: type,
        item_ids,
      });
      if (response.errors) {
        return info({
          icon: <></>,
          title: (
            <span style={{ fontWeight: "bold" }}>Something went wrong</span>
          ),
          content: response.errors[0].message,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      key="PopupOrderDetail"
      centered={true}
      destroyOnClose={true}
      x
      className={`modal-cooking popup-orderDetail ${
        isLightMode ? "popup-orderDetail-light" : ""
      }`}
      title={null}
      closeIcon={<></>}
      open={isShowOrderDetail}
      width={442}
      onCancel={onCloseModal}
      footer={null}
    >
      <Spin spinning={isLoading}>
        <div
          style={{
            borderBottom: "1px solid rgba(237, 237, 237, 1)",
            paddingBlock: 16,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <h3
              style={{
                color: "#000",
                fontSize: 16,
                fontWeight: 600,
                marginBottom: 20,
              }}
            >
              {getTextNameTable()}
            </h3>
            <div
              style={{
                fontSize: 16,
                color: "#5B5B5B",
              }}
              className={` ${isLightMode ? "text-black" : "text-white"}`}
            >
              {moment(order?.created_at).format("HH:mm A")}
            </div>
          </div>
          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {!isCancelAll && (
              <div
                style={{
                  marginLeft: 24,
                }}
                onClick={onRePrint}
              >
                <PrinterIcon />
              </div>
            )}
            <div
              style={{
                width: "fit-content",
                fontSize: 16,
                fontWeight: 600,
                color: getTypeTextOrder(order).color,
                backgroundColor: getTypeTextOrder(order).backgroundColor,
                padding: "5px 12px",
                borderRadius: "36px",
                marginLeft: "auto",
                textAlign: "center",
              }}
            >
              {getTypeTextOrder(order).text}
            </div>
          </div>
        </div>
        {order?.assign_from?.name && order?.status === "pending" && (
          <div className="order-item-moved">
            <RightCircleOutlined />
            {`${t("movedFrom")} ${order?.assign_from?.name}`}
          </div>
        )}
        <div
          className={`order-item-box ${
            isLightMode ? "order-item-box-light" : ""
          }`}
          style={{
            marginTop: 24,
          }}
        >
          {order?.items.map((item, index) => (
            <div
              className={`${
                isLightMode ? "order-item-info-light" : "order-item-info"
              }`}
              key={`${item.id}-${index}`}
            >
              <div
                className="flex-sb-c"
                style={{
                  color: item?.status === "cancel" ? "red" : "",
                  textDecoration:
                    item?.status === "cancel" ? "line-through" : "auto",
                }}
              >
                <span
                  className={` order-item-product-name ${
                    isLightMode ? "text-black" : ""
                  }`}
                  style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: item?.status === "cancel" ? "red" : "",
                  }}
                >
                  {item?.product_name}
                </span>

                <div
                  className={` ${isLightMode ? "text-black" : "text-white"}`}
                  style={{
                    marginRight: 8,
                    fontWeight: 600,
                    fontSize: 16,
                    marginLeft: "auto",
                    width: "fit-content",
                    color: item?.status === "cancel" ? "red" : "",
                  }}
                >
                  {item?.quantity ? `${item?.quantity}x` : "1x"}
                </div>
              </div>

              <div className="order-item-product-notes-container">
                <span
                  className={`order-item-product-modifier ${
                    isLightMode ? "note-light-mode" : ""
                  }`}
                  style={{
                    paddingLeft: item?.note ? 30 : 0,
                  }}
                >
                  {item?.product_options?.map((itemOption, itemOptionIndex) => {
                    return itemOption.values.map(
                      (itemValue, itemValueIndex) => {
                        const isLastItemOption =
                          itemOptionIndex === item.product_options.length - 1;
                        const isLastItemValue =
                          itemValueIndex === itemOption.values.length - 1;
                        const isLast = isLastItemOption && isLastItemValue;
                        return (
                          <>
                            {itemValue.option_child_title}
                            {!isLast && ","}
                          </>
                        );
                      }
                    );
                  })}
                </span>
              </div>
              {item?.note && (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  <span
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: 14,
                      marginRight: 4,
                    }}
                  >
                    Note:
                  </span>
                  <span style={{ color: "black", fontSize: 14 }}>
                    {" "}
                    {item?.note}
                  </span>
                </div>
              )}
            </div>
          ))}
        </div>
        {renderBottom()}
      </Spin>
    </Modal>
  );
}
