import React, { useContext, useState } from "react";

import { ThemeContext } from "handlers/ThemeContext";
import Notify from "../components/Notify";
import { getLocalStorage } from "utils/CommomFunction";
import { useTranslation } from "react-i18next";
import HomeIcon from "./icon/HomeIcon";
import Footer from "./footer";
import { emitter } from "apis/apiWithCancel";
export default function Container({ children }) {
  const { isLightMode, toggleMode } = useContext(ThemeContext);
  const isLogin = () => {
    return getLocalStorage("access_token");
  };
  const { t, i18n } = useTranslation();
  const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
  };

  const onClickLogo = () => {
    window.location.href = "/home";
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          background: isLightMode ? "#fff" : "#0B111D ",
          justifyContent: "space-between",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Added box-shadow
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
          <div
            style={{
              backgroundColor: "#FF9D00",
              height: 72,
              width: 72,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={onClickLogo}
          >
            <HomeIcon color="white" />
          </div>
        </div>
        <div style={{ display: "flex", gap: 20 }}>
          {isLogin() && <Notify />}
        </div>
      </div>

      {children}
      <Footer onChangeDate={() => emitter.emit("ChangeDate")} />
    </div>
  );
}
