import { DatePicker } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import backSvg from "./back.svg";
import nextSvg from "./next.svg";
import moment from "moment";

export const Canlendar = ({ date, setDate }) => (
  <DateContainer>
    <NavigationIcon
      loading="lazy"
      src={backSvg}
      alt="Previous"
      onClick={() =>
        setDate(dayjs(date).subtract(1, "day").format("YYYY-MM-DD"))
      }
    />
    <DatePicker
      placeholder="Borderless"
      variant="borderless"
      suffixIcon={null}
      format={"dddd, MMMM D"}
      onChange={(data) => {
        setDate(data.format("YYYY-MM-DD"));
      }}
      allowClear={false}
      value={moment(date, "YYYY-MM-DD")}
    />
    <NavigationIcon
      loading="lazy"
      src={nextSvg}
      alt="Next"
      onClick={() => setDate(dayjs(date).add(1, "day").format("YYYY-MM-DD"))}
    />
  </DateContainer>
);

const DateContainer = styled.div`
  border-radius: 6px;
  border: 1px solid var(--Green-Prima, #0aa699);
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  color: var(--Green-Prima, #0aa699);
  text-transform: capitalize;
  letter-spacing: 0.6px;
  flex-grow: 1;
  flex-basis: auto;
  padding: 9px 11px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
  width: 470px;

  .ant-picker {
    width: 300px;
    input {
      font-size: 20px;
      text-align: center;
      width: auto;
      color: #0aa699;
      text-transform: capitalize;
      letter-spacing: 0.6px;
      font-weight: 600;
      font-family: "Montserrat";
    }
    background: none;
    border: none;
  }
`;

const NavigationIcon = styled.img`
  aspect-ratio: 1.19;
  object-fit: contain;
  object-position: center;
  width: 38px;
  border-radius: 0px;
  cursor: pointer;
`;
