import { callApi } from "apis";

export const apiGetOrderDetail = (data) =>
  callApi(
    `query ($id: Int!) {
        orderDetail(id: $id) {
          id
          order_number
          created_at
          updated_at
          grand_total
          status
          flag_refund
          payment_method
          payment_method_code
          firstname
          lastname
          phone
          address
          waiter_name
          customer_comment
          pickup_date
          pickup_time
          items {
            name
            qty
            price
            serving_status
            options {
              name
              qty
              price
              __typename
            }
            __typename
          }
          discount {
            amount {
              currency
              value
              __typename
            }
            label
            __typename
          }
          shipping_method
          use_plastic
          note
          customer_phone
          assign_from
          assign_reason
          shipping_amount
          table
          order_source
          serve_name
          restaurant_name
          restaurant_address
          restaurant_phone
          total {
            subtotal {
              value
              currency
            }
            discounts {
              amount {
                value
                currency
              }
              label
            }
            total_tax {
              value
              currency
            }
            taxes {
              amount {
                value
                currency
              }
              title
              rate
            }
            total_shipping {
              value
              currency
            }
            grand_total {
              value
              currency
            }
          }
          feedback_url
          payment_methods {
            name
            type
            additional_data {
              name
              value
            }
          }
          tip_amount {
            value
            currency
            __typename
          }
          can_refund
          is_refunded
          __typename
        }
      }`,
    { id: data?.id }
  );

export const rePrintOrder = (data) =>
  callApi(
    `mutation($cart_id: Int!, $bill_type: ItemTypeEnum! , $item_ids: [Int!]) {
          kitchenRePrint(cart_id: $cart_id, bill_type: $bill_type,item_ids: $item_ids)
        }`,
    data
  );
