import { callApi } from "..";

export const apiChangeStoreStatus = (data) =>
  callApi(
    `mutation (
    $status: Int!
    $weekday: String!
    $weekend: String!
  ) {
    storeStatus(
        status: $status
        weekday: $weekday
        weekend: $weekend
    ) {
        result
    }
}`,
    data
  );
export const apiAttendance = () =>
  callApi(
    `
  mutation{
    dailyAttendance {
      result
    }
  }
  `,
    null
  );

export const getStoreInfo = () =>
  callApi(
    `{
  getMerchantInfo {
      lastname
      firstname
      restaurant_address
      restaurant_name
      restaurant_code
      restaurant_id
      store_view_code
      kitchen_stations {
            id
            name
      }
  }
}`,
    null
  );

export const getStoreConfig = () =>
  callApi(
    `{
  merchantGetRestaurantConfig {
    auto_confirm_item
    minimum_ticket_length
    close_order
    open_pricing
    primary_terminal_setting
    business_day_start
    time_zone
    option_dine_in
    option_delivery
    option_catering
    option_pickup
    option_reservation
    cash_checkout_waiter
    __typename
  }
}`,
    null
  );
